import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Chip from '@mui/material/Chip'
import Modal from '@mui/material/Modal'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import React, { useState } from 'react'
import Layout from '../components/Layout'
import { Newsletter } from '../components/Newsletter'
import Seo from '../components/Seo'

interface CoursesPageProps {
	location?: PageLocation, // Not available during SSR
}

const CoursesPage: React.FC<CoursesPageProps> = () => {

	const [subscribeModelOpen, setSubscribeModelOpen] = useState(false)

	return (
		<Layout>
			<Seo title="Courses" description="The dev tavern courses" />
			<Typography fontWeight="bold" variant="h1" gutterBottom>Courses</Typography>
			<Typography variant="h4" my="2rem" mt="4rem" fontWeight="bold">Upcoming courses</Typography>

			<Card sx={{ mx: "2rem", borderRadius: "0.5rem", display: "flex", flexDirection: "column" }}>
				<CardMedia
					component="img"
					width="320"
					image="/assets/images/kotlin-coroutines-course-logo.png"
					alt="Kotlin"
					sx={{ objectFit: "cover" }}
				/>
				<CardContent sx={{ flexGrow: 1 }}>
					<Typography mb="1rem" variant="h5" component="div">
						Kotlin coroutines and concurrency In Depth
					</Typography>
					<Chip label="Coming soon" size="small" variant="filled" color="secondary" />
					<Typography variant="body1" color="text.secondary" my="1.5rem">
						Coroutines give you incredible power as a programmer once you master them! Their usefulness goes beyond just simplifying concurrency.
					</Typography>
					<Typography variant="body1" color="text.secondary" my="1.5rem">
						However, the path to mastering coroutines and the large ecosystem around them is challenging. The road is full of difficulties ranging from unexpected behaviors and slowness to many other sorts of problems that will make you feel not productive enough in the early stages.
					</Typography>
					<Typography variant="body1" color="text.secondary" my="1.5rem">
						This course's ambition is to help you unlock the full power behind Kotlin Coroutines in the fastest and smoothest way possible by going in-depth into how they work and helping you build an accurate mental model to correctly reason about them. We will explore the large ecosystem around them and systematically combine theoretical explanations with practical and real-world examples and projects.
					</Typography>
					<Typography variant="body1" color="text.secondary" my="0.5rem">
						This course is not available yet but will be released soon. Stay tuned for updates by subscribing to the mailing list below.
					</Typography>
				</CardContent>
				<CardActions>
					<Button size="small" onClick={() => setSubscribeModelOpen(true)}>Subscribe for updates</Button>
				</CardActions>
			</Card>

			<Modal
				keepMounted
				open={subscribeModelOpen}
				onClose={() => setSubscribeModelOpen(false)}
				aria-label="Subscription modal"
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center"
				}}
			>
				<Paper
					sx={{
						outline: "none",
						maxWidth: "sm",
						p: "2rem",
						m: "2rem"
					}}
				>
					<Newsletter uid="d0dabd314d" formId="2831206">
						<Typography variant="h5" fontWeight="bold" gutterBottom>Subscribe for notifications on this course!</Typography>
						<Typography variant="body1" color="text.secondary" gutterBottom>
							Follow the progress made on this course and be notified of any update until its release!
						</Typography>
					</Newsletter>
				</Paper>
			</Modal>
		</Layout >
	)
}

export default CoursesPage