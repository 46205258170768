import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import React from "react";

export interface NewsletterProps {
	uid: string,
	formId: string,
}

export const Newsletter: React.FC<NewsletterProps> = (props) => {

	const options = {
		"settings": {
			"after_subscribe": {
				"action": "message",
				"success_message": "Thank you for subscribing! Now check your emails to confirm your subscription.",
				"redirect_url": ""
			},
			"analytics": {
				"google": null,
				"facebook": null,
				"segment": null,
				"pinterest": null,
				"sparkloop": null,
				"googletagmanager": null
			},
			"modal": {
				"trigger": "timer",
				"scroll_percentage": null,
				"timer": 5,
				"devices": "all",
				"show_once_every": 15
			},
			"powered_by": {
				"show": true,
				"url": "https://convertkit.com/features/forms?utm_campaign=poweredby&utm_content=form&utm_medium=referral&utm_source=dynamic"
			},
			"recaptcha": {
				"enabled": false
			},
			"return_visitor": {
				"action": "hide",
				"custom_content": ""
			},
			"slide_in": {
				"display_in": "bottom_right",
				"trigger": "timer",
				"scroll_percentage": null,
				"timer": 5,
				"devices": "all",
				"show_once_every": 15
			},
			"sticky_bar": {
				"display_in": "top",
				"trigger": "timer",
				"scroll_percentage": null,
				"timer": 5,
				"devices": "all",
				"show_once_every": 15
			}
		},
		"version": "5"
	}

	return (
		<>
			<Box
				component="form"
				action={`https://app.convertkit.com/forms/${props.formId}/subscriptions`}
				method="post"
				target='_blank'
				data-sv-form={props.formId}
				data-uid={props.uid}
				// data-format={format}
				data-version={options.version}
				data-options={JSON.stringify(options)}
				display="flex"
				flexDirection="column"
				gap="1rem"
			>
				{/* <FormControl>
					<InputLabel htmlFor="ck-email">Email address</InputLabel>
					<Input required name="email_address" type="email" id="ck-email" aria-describedby="email-helper-text" />
					<FormHelperText id="email-helper-text">We'll never share your email.</FormHelperText>
				</FormControl> */}
				{props.children && <Box>{props.children}</Box>}

				<TextField
					label="Email address"
					name="email_address"
					type="email"
					required
					variant="outlined"
					sx={(theme) => ({
						'& .MuiOutlinedInput-root, & .MuiOutlinedInput-notchedOutline': {
							'& fieldset': {
								borderColor: "white",
							},
							'&:hover fieldset': {
								borderColor: 'white',
							},
							'&.Mui-focused fieldset': {
								borderColor: theme.palette.primary.main,
							},
						},
					})}
				/>
				<FormControl>
					<FormControlLabel
						label="Also subscribe me to the newsletter once it is ready!"
						control={<Checkbox name="tags[]" value="2797747" />}
					/>
				</FormControl>
				<Button type="submit">Subscribe</Button>


				{/* <label htmlFor="ck-email">Email</label>
				<input type="email" name="email_address" required aria-required placeholder="Your email address..." aria-label="Email" id="ck-email" /> */}
				{/* <input className="formkit-checkbox" id="tag-192115377-2797747" type="checkbox" name="tags[]" value="2797747" />
				<label htmlFor="tag-192115377-2797747">Also subscribe me to the newsletter once it is ready!</label> */}
				{/* <button type="submit">Subscribe</button> */}
			</Box>





			{/* <form action="https://app.convertkit.com/forms/2831206/subscriptions" className="seva-form formkit-form" method="post" data-sv-form="2831206" data-uid="d0dabd314d" data-format="inline" data-version="5" data-options="" min-width="400 500 600 700 800">
				<div data-style="clean">
					<ul className="formkit-alert formkit-alert-error" data-element="errors" data-group="alert"></ul>
					<div data-element="fields" data-stacked="false" className="seva-fields formkit-fields">
						<div className="formkit-field"><input className="formkit-input" name="email_address" aria-label="Email Address" placeholder="Email Address" required type="email" /></div>
						<div className="formkit-field">
							<div role="button" tabIndex={0}>
								<fieldset data-group="checkboxes" className="formkit-1859" type="Custom" order="1" custom_field_key="first_name" custom_field_label="First Name" save_as="Tag" group="field">
									<div className="formkit-checkboxes" data-element="tags-checkboxes" data-group="checkbox"><input className="formkit-checkbox" id="tag-192115377-2797747" type="checkbox" name="tags[]" value="2797747" /><label for="tag-192115377-2797747">Also subscribe me to the newsletter once it is ready!</label></div>
								</fieldset>
							</div>
						</div>
						<button data-element="submit" className="formkit-submit formkit-submit">
							<div className="formkit-spinner">
								<div></div>
								<div></div>
								<div></div>
							</div>
							<span className="">Subscribe</span>
						</button>
					</div>
					<div className="formkit-powered-by-convertkit-container"><a href="https://convertkit.com/features/forms?utm_campaign=poweredby&amp;utm_content=form&amp;utm_medium=referral&amp;utm_source=dynamic" data-element="powered-by" className="formkit-powered-by-convertkit" data-variant="dark" target="_blank" rel="noopener noreferrer">Built with ConvertKit</a></div>
				</div>
			</form> */}
		</>
	)
}